import React, { useState, useEffect } from 'react';

const TemplateInput = ({onValueSave}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        const storedValue = localStorage.getItem('templateInput');
        if (storedValue) {
            setValue(storedValue);
        } else {
            setValue(
                '#GUY, #здравствуйте, обратил внимание, что «#COMPANYNAME#» активно нанимает разработчиков на Yii2, у нас как раз есть свободный и мы можем предложить его для выполнения задач, подскажите, с кем можно связаться по этому вопросу?'
            );
        }
    }, []);

    const handleSave = () => {
        localStorage.setItem('templateInput', value);
        console.log(value);
        onValueSave(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    return (
        <div className="input-group mb-3">
      <textarea
          className="form-control"
          rows="5"
          value={value}
          onChange={handleChange}
      />
            <button
                className="btn btn-primary"
                type="button"
                onClick={handleSave}
            >
                Сохранить
            </button>
        </div>
    );
};

export default TemplateInput;